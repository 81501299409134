<template>
  <div class="h-100">
    <!-- <div>
      <TheTitleBar
        sectionTitle="dashboard.label.mnuMyAccount"
        sectionID="dashboard.label.mnuMyAccount"
        :showCount="false"
        :showValue="false"
        :showFilters="false"
        :showSort="false"
        :showSellButton="false"
      />
    </div> -->
    <b-row class="py-3 py-md-4 mx-0">
      <b-col class="benefits rounded mr-3 col-md-3 col-12 p-3">
        <!-- <div class="text-center p-4">
          <p class="text-capitalize" v-if="user.FirstName && user.LastName">
            {{ user.FirstName }} {{ user.LastName }}
          </p>
          <p class="pt-2 text-half-dark">{{ user.Email }}</p>
        </div> -->

        <!-- GettingPaid -->
        <router-link
          :to="{ name: 'GettingPaid' }"
          class="routerLink"
          v-show="$store.getters.user.Roles.includes('Seller')"
        >
          <b-list-group-item
            button
            :active="isActive('gettingpaid')"
            class="d-flex align-items-center border-0"
          >
            <span class="mr-auto">{{
              $t("dashboard.label.accountPayment")
            }}</span>
          </b-list-group-item></router-link
        >
      </b-col>
      <b-col class="my-0">
        <router-view></router-view>
        <b-alert
          variant="success"
          :show="$route.path === '/dashboards/payment/gettingpaid'"
          dismissible
          class="p-4 my-3"
        >
          <h5>
            <b-avatar variant="success" size="1.4em"
              ><b-icon-shield-check scale="0.8"
            /></b-avatar>
            <b class="px-2">We care about your privacy & safety</b>
          </h5>
          <span>
            That’s why we manage the entire selling process and never share your
            address with buyers. <br />
            All they’ll see is the country and city.
          </span>
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import TheTitleBar from "@/components/dashboard/global/TheTitleBar.vue";

export default {
  components: {
    // TheTitleBar
  },
  computed: {},
  methods: {
    isActive(path) {
      return this.$route.path.indexOf(path) !== -1;
    }
  }
};
</script>
<style scoped>
.list-group-item {
  border-left: 5px solid rgba(34, 119, 239, 0) !important;
}

.list-group-item.active {
  border-left: 5px solid rgba(34, 119, 239, 1) !important;
  background-color: rgba(34, 119, 239, 0.1) !important;
  color: rgba(34, 119, 239, 1);
  font-weight: bold;
}
</style>
